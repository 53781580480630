/* eslint-disable menti-react/filename-convention--jsx */
import { Trans, type TFunction } from '@mentimeter/i18n';
import React, { type JSX } from 'react';
import type { TextT } from '@mentimeter/ragnar-ui';
import type { FaqT } from './types';

export function getPlansFaqConfig({
  t,
  Link,
  Bold,
  isInMonthlyTreatment,
  isEducation,
}: {
  t: TFunction;
  Link: React.ComponentType<{ href?: string; children?: React.ReactNode }>;
  Bold: (props: TextT) => JSX.Element;
  isInMonthlyTreatment: boolean;
  isEducation: boolean;
}): FaqT {
  return [
    {
      question: t('plans.faq.can_i_pay_with_invoice'),
      answer: (
        <Trans
          t={t}
          i18nKey="plans.faq.can_i_pay_with_invoice_answer"
          components={{
            Bold: <Bold />,
            Link: <Link href="/app/invoice-request" />,
          }}
        />
      ),
    },
    {
      question: t('plans.faq.why_is_mentimeter_free'),
      answer: t('plans.faq.why_is_mentimeter_free_answer'),
    },
    {
      question: t('plans.faq.what_is_participants_per_month'),
      answer: t('plans.faq.what_is_participants_per_month_answer'),
    },
    {
      question: t('plans.faq.monthly_participants_limit'),
      answer: t('plans.faq.monthly_participants_limit_answer'),
    },
    {
      question: t('plans.faq.alternative_payment_options'),
      answer: (
        <Trans
          t={t}
          i18nKey="plans.faq.alternative_payment_options_answer"
          components={{
            Bold: <Bold />,
            Link: <Link href="/app/invoice-request" />,
          }}
        />
      ),
    },
    {
      question: t('plans.faq.will_you_store_credit_card'),
      answer: (
        <Trans
          t={t}
          i18nKey="plans.faq.will_you_store_credit_card_answer"
          components={{
            BrainTreeLink: (
              <Link href="https://www.braintreepayments.com/products-and-features/data-security" />
            ),
            StripeLink: <Link href="https://stripe.com/docs/security" />,
            br: <br />,
          }}
        />
      ),
    },
    isInMonthlyTreatment
      ? {
          question: t('plans.faq.pay_for_one_month'),
          answer: (
            <Trans
              t={t}
              i18nKey="plans.faq.pay_for_one_month_monthly_billing_answer"
              components={{
                Link: <Link href="/plans/conference" />,
              }}
            />
          ),
        }
      : {
          question: t('plans.faq.pay_for_one_month'),
          answer: (
            <Trans
              t={t}
              i18nKey="plans.faq.pay_for_one_month_answer"
              components={{
                Link: <Link href="/plans/conference" />,
              }}
            />
          ),
        },
    {
      question: t('plans.faq.why_annual_payments'),
      answer: t('plans.faq.why_annual_payments_answer'),
    },
    {
      question: t('plans.faq.change_mind'),
      answer: t('plans.faq.change_mind_answer'),
    },
    ...(isEducation
      ? [
          {
            question: t('plans.education.do_you_have_special_pricing_for_k-12'),
            answer: (
              <Trans
                t={t}
                i18nKey="plans.education.do_you_have_special_pricing_for_k-12_answer"
                components={{
                  Bold: <Bold />,
                }}
              />
            ),
          },
          {
            question: t(
              'plans.education.do_you_have_a_discount_for_non-profit_organizations',
            ),
            answer: (
              <Trans
                t={t}
                i18nKey="plans.education.do_you_have_a_discount_for_non-profit_organizations_answer"
                components={{
                  Email: <Link href="mailto:hello@mentimeter.com" />,
                }}
              />
            ),
          },
        ]
      : [
          {
            question: t('plans.faq.educational_pricing'),
            answer: (
              <Trans
                t={t}
                i18nKey="plans.faq.educational_pricing_answer"
                components={{
                  Link: <Link href="/plans/education" />,
                }}
              />
            ),
          },
        ]),
    {
      question: t('plans.faq.which_types_of_questions'),
      answer: (
        <Trans
          t={t}
          i18nKey="plans.faq.which_types_of_questions_answer"
          components={{
            FeaturesLink: <Link href="/features" />,
            GuidesLink: <Link href="/guides" />,
          }}
        />
      ),
    },
    {
      question: t('plans.faq.quiz_question_difference'),
      answer: (
        <Trans
          t={t}
          i18nKey="plans.faq.quiz_question_difference_answer_series_limit_experiment"
          components={{
            Link: <Link href="/campaigns/quiz-energy-level" />,
          }}
        />
      ),
    },
    {
      question: t('plans.faq.exported_result_looks'),
      answer: (
        <Trans
          t={t}
          i18nKey="plans.faq.exported_result_looks_answer"
          components={{
            Link: (
              <Link href="https://help.mentimeter.com/after-the-presentation/export-results-to-excel?_ga=2.29792253.373225288.1553495870-365774505.1529394414" />
            ),
          }}
        />
      ),
    },
    {
      question: t('plans.faq.customize_and_brand'),
      answer: t('plans.faq.customize_and_brand_answer'),
    },
    {
      question: t('plans.faq.share_login'),
      answer: t('plans.faq.share_login_answer'),
    },
    {
      question: t('plans.faq.same_presentation_several_locations'),
      answer: (
        <Trans
          t={t}
          i18nKey="plans.faq.same_presentation_several_locations_answer"
          components={{
            Bold: <Bold />,
          }}
        />
      ),
    },
  ];
}
