import { getLocalUser } from '@mentimeter/user';
import {
  createSplitEnvironment,
  getDefaultUserAttributes,
  getDeviceAttributes,
  getSplitDataSync,
} from '@mentimeter/splitio';
import {
  clientSidePreferredLanguage,
  getLocaleFromPathname,
} from '@mentimeter/i18n';
import { LOCALES, DEFAULT_LOCALE } from 'src/constants.cjs';
import {
  visitorExperiments,
  userExperiments,
  type ContentVisitorExperiments,
  type ContentUserExperiments,
} from './split-experiments';

function getVisitorAttributes() {
  const locale = getLocaleFromPathname(LOCALES, DEFAULT_LOCALE);
  const currentLocale =
    locale !== DEFAULT_LOCALE
      ? locale
      : clientSidePreferredLanguage(LOCALES, DEFAULT_LOCALE);

  return {
    ...getSplitDataSync(),
    ...getDeviceAttributes(),
    locale: currentLocale,
  };
}

const { useSplits: useVisitorSplits, SplitProvider: SplitIOVisitorProvider } =
  createSplitEnvironment<ContentVisitorExperiments>(visitorExperiments, {
    getAttributes: getVisitorAttributes,
  });

function getUserAttributes() {
  const user = getLocalUser();
  if (user) {
    return {
      devEnv: Boolean(process.env.NEXT_PUBLIC_DEV_ENVIRONMENT),
      ...getSplitDataSync(),
      ...getDefaultUserAttributes(),
      ...getDeviceAttributes(),
    };
  }
  return getSplitDataSync();
}

const {
  useSplits: useUserSplits,
  useSplitsLazy: useUserSplitsLazy,
  SplitProvider: SplitIOUserProvider,
} = createSplitEnvironment<ContentUserExperiments>(userExperiments, {
  getAttributes: getUserAttributes,
});

export {
  useVisitorSplits,
  useUserSplits,
  useUserSplitsLazy,
  SplitIOVisitorProvider,
  SplitIOUserProvider,
};
