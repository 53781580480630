import { useTranslations } from '@mentimeter/i18n';
import { useVisitorSplits } from 'src/split-hooks';
import { getSplitDataSync, useExperiment } from '@mentimeter/splitio';
import { userPolicies, useUser } from '@mentimeter/user';
import type { PlansPageConfigT } from '@mentimeter/plans-content';
import { getPlansPageConfig } from '@mentimeter/plans-content';
import { Link } from 'src/ui/actions';
import {
  DEFAULT_PRICE,
  useSubscriptions,
  useUpgradeOptions,
} from '@mentimeter/billing';
import { trackVisitor } from 'src/trackVisitor';
import { useEngagementLimits } from '@mentimeter/paywalls-data-hooks';
import { trackUser } from '@api/tracking/client';
import { useCallback, useEffect, useState } from 'react';
import { usePrices } from './usePrices';
import { useTrackPlan } from './useTrackPlan';
import { usePlansPageStaircaseSplit } from './usePlasPageStairCaseSplit';

export const useExperimentParameters = () => {
  const { Monthly_Billing } = useVisitorSplits(['Monthly_Billing']);

  const isInMonthlyTreatment = Monthly_Billing === 'on';

  const { isInStairCaseTreatment, plansPageStairCaseExperimentReady } =
    usePlansPageStaircaseSplit();

  const isReady =
    Monthly_Billing !== 'not_ready' && plansPageStairCaseExperimentReady;

  return {
    isReady,
    isInMonthlyTreatment,
    isInStairCaseTreatment,
  };
};

export const usePlansConfig = ({ isEducation }: { isEducation: boolean }) => {
  const [monthlyToggled, setMonthlyToggled] = useState(false);
  const { user } = useUser();
  const isFreeUser = user?.isFreeUser ?? false;
  const { subscriptions } = useSubscriptions(!user);
  const { country, preferredLanguage } = getSplitDataSync();
  const t = useTranslations('plansContentPackage');
  const isLoggedIn = Boolean(user);
  const { isReady, isInMonthlyTreatment, isInStairCaseTreatment } =
    useExperimentParameters();
  const engagementLimits = useEngagementLimits();
  const { isBasicMonthlyUser } = userPolicies(subscriptions);
  const {
    prices: dynamicPrices,
    error: priceError,
    isLoading: isPriceLoading,
  } = usePrices();
  const { possibleUpgrades } = useUpgradeOptions(!user);
  const showDynamicPrices = !priceError && !isPriceLoading;
  const { isInExperiment: isInPlanNameExperiment } = useExperiment(
    'PAY_Plan_Name_Experiment',
    ['on'],
  );
  const { trackPlan } = useTrackPlan(isBasicMonthlyUser);

  const toggleSwitch = useCallback(() => {
    const payload = {
      event: 'Clicked billing toggle',
      properties: {
        context: 'Plans page',
        cycle: monthlyToggled ? 'yearly' : 'monthly',
      },
    };
    if (user) {
      trackUser(payload);
    } else {
      trackVisitor(payload);
    }
    setMonthlyToggled((value) => !value);
  }, [user, monthlyToggled]);

  const [config, setConfig] = useState<PlansPageConfigT>(
    getPlansPageConfig({
      isInPlanNameExperiment,
      isEducation,
      isFreeUser: false,
      loggedIn: isLoggedIn,
      userPolicies: userPolicies(subscriptions),
      prices:
        showDynamicPrices && dynamicPrices ? dynamicPrices : DEFAULT_PRICE,
      isPriceLoading,
      monthlyToggled,
      possibleUpgrades,
      isInMonthlyTreatment,
      isInStairCaseTreatment,
      toggleSwitch,
      userEngagementLimitProgress:
        engagementLimits?.engagementData.progress ?? undefined,
      host: '', // Note 2022-08-31: Buttons in content cannot handle absolute URLs, and require relative URLs even for redirecting to another application.
      Link,
      trackPlan,
      t,
      country,
    }),
  );

  useEffect(() => {
    setConfig(
      getPlansPageConfig({
        isInPlanNameExperiment,
        isEducation,
        isFreeUser,
        loggedIn: isLoggedIn,
        userPolicies: userPolicies(subscriptions),
        prices:
          showDynamicPrices && dynamicPrices ? dynamicPrices : DEFAULT_PRICE,
        isPriceLoading,
        monthlyToggled,
        possibleUpgrades,
        isInMonthlyTreatment,
        isInStairCaseTreatment,
        toggleSwitch,
        userEngagementLimitProgress:
          engagementLimits?.engagementData.progress ?? undefined,
        host: '', // Note 2022-08-31: Buttons in content cannot handle absolute URLs, and require relative URLs even for redirecting to another application.
        Link,
        trackPlan,
        t,
        country,
      }),
    );
  }, [
    user,
    isReady,
    monthlyToggled,
    possibleUpgrades,
    preferredLanguage,
    country,
    isEducation,
    isFreeUser,
    t,
    trackPlan,
    toggleSwitch,
    isInMonthlyTreatment,
    isLoggedIn,
    dynamicPrices,
    priceError,
    isPriceLoading,
    engagementLimits?.engagementData.progress,
    subscriptions,
    showDynamicPrices,
    isInStairCaseTreatment,
    isInPlanNameExperiment,
  ]);

  return config;
};
