import { useVisitorSplits } from 'src/split-hooks';

export function usePlansPageStaircaseSplit() {
  const visitorSplits = useVisitorSplits(['PUG_Plans_Page_Staircase']);

  return {
    isInStairCaseTreatment: visitorSplits.PUG_Plans_Page_Staircase === 'v1',
    plansPageStairCaseExperimentReady: visitorSplits.isReady,
  };
}
