interface NavigatorWithNonStandardLang extends Navigator {
  userLanguage?: string;
  browserLanguage?: string;
}

export function clientSidePreferredLanguage(
  locales: string[],
  defaultLocale: string,
): string | undefined {
  const { userLanguage, language, browserLanguage } =
    navigator as NavigatorWithNonStandardLang;
  const languages = navigator.languages || [
    userLanguage || language || browserLanguage,
  ];

  return preferredSupportedLanguage(languages, locales, defaultLocale);
}

const LOCALE_MAPPINGS: Record<string, string> = {
  'en-gb': 'en-US',
  en: 'en-US',
  'es-419': 'es-ES',
};

export function preferredSupportedLanguage(
  languages: readonly string[],
  locales: string[],
  defaultLocale: string,
): string | undefined {
  // Maps lower case locale to a supported locale (e.g. en-GB -> en-US)
  const localeMap = locales.reduce(
    (acc, locale) => ({ ...acc, [locale.toLocaleLowerCase()]: locale }),
    LOCALE_MAPPINGS,
  );

  // Find first match in languages that is a supported language
  const matchingLocale = languages
    .map((language) => localeMap[language.toLocaleLowerCase()])
    // Makes sure that locale is actually supported (e.g. es-ES might not be supported by application)
    .filter(
      (language) => language !== undefined && locales.includes(language),
    )[0];

  return matchingLocale || defaultLocale;
}
